<template>
    <v-dialog
        persistent
        v-model="uploadDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
    >
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <v-stepper v-model="uploadWizard">
                    <v-stepper-header>
                        <v-stepper-step :complete="uploadWizard > 1" step="1">
                            {{ $t('message.readingFiles') }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="uploadWizard > 2" step="2">
                            {{ $t('message.checkBundles') }}
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step :complete="uploadWizard > 3" step="3">
                            {{ $t('message.addToList') }}
                        </v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <div class="d-flex flex-row pb-3">
                                <v-form lazy-validation ref="fileUploadForm" v-model="validForm">
                                    <div class="d-flex flex-column" style="min-width: 400px !important;">
                                        <v-checkbox
                                            :label="$t('message.separatedPages')"
                                            :true-value="true"
                                            :false-value="false"
                                            class="ml-0 black--text text--darken-1"
                                            dense
                                            hide-details="auto"
                                            v-model="separatedPages"
                                        ></v-checkbox>
                                        <div class="d-flex flex-row align-center pt-3">
                                            <span v-if="separatedPages">{{ '#1' }}</span>
                                            <v-file-input
                                                :placeholder="$t('message.selectTallySheetFile')"
                                                :error-messages="manualErrorState[0]"
                                                :rules="[...validationRules.required]"
                                                accept="application/pdf"
                                                dense
                                                hide-details="auto"
                                                solo
                                                v-model="tallySheetFile"
                                                v-if="hasFile.tallySheet"
                                                @change="checkFile(0 ,'pdf', $event)"
                                            />
                                        </div>
                                        <div class="d-flex flex-row align-center pt-3" v-if="separatedPages">
                                            <span v-if="separatedPages">{{ '#2' }}</span>
                                            <v-file-input
                                                :placeholder="$t('message.selectTallySheetFile')"
                                                :error-messages="manualErrorState[1]"
                                                :rules="[...validationRules.required]"
                                                accept="application/pdf"
                                                dense
                                                hide-details="auto"
                                                solo
                                                v-model="tallySheetFileTwo"
                                                v-if="hasFile.tallySheet"
                                                @change="checkFile(1 ,'pdf', $event)"
                                            />
                                        </div>
                                        <div class="d-flex flex-row align-center pt-3" v-if="separatedPages">
                                            <span v-if="separatedPages">{{ '#3' }}</span>
                                            <v-file-input
                                                :placeholder="$t('message.selectTallySheetFile')"
                                                :error-messages="manualErrorState[2]"
                                                accept="application/pdf"
                                                dense
                                                hide-details="auto"
                                                solo
                                                v-model="tallySheetFileThree"
                                                v-if="hasFile.tallySheet"
                                                @change="checkFile(2 ,'pdf', $event)"
                                            />
                                        </div>
                                        <div class="d-flex flex-row align-center pt-3" v-if="hasFile.sizes">
                                            <v-file-input
                                                :placeholder="$t('message.selectSizeSampleImage')"
                                                :error-messages="manualErrorState[3]"
                                                :rules="[...validationRules.required]"
                                                accept="image/jpeg, image/gif, image/png"
                                                dense
                                                hide-details="auto"
                                                solo
                                                v-model="tallySheetSizesFile"
                                                @change="checkFile(3, 'image', $event)"
                                            />
                                            <v-btn icon class="ml-1">
                                                <v-icon>help_outline</v-icon>
                                            </v-btn>
                                        </div>
                                        <div class="d-flex flex-row align-center pt-3" v-if="hasFile.quantities">
                                            <v-file-input
                                                :placeholder="$t('message.selectQuantitySampleImage')"
                                                :error-messages="manualErrorState[4]"
                                                :rules="[...validationRules.required]"
                                                accept="image/jpeg, image/gif, image/png"
                                                dense
                                                hide-details="auto"
                                                solo
                                                v-model="tallySheetQuantitiesFile"
                                                @change="checkFile(4, 'image', $event)"
                                            />
                                            <v-btn icon class="ml-1">
                                                <v-icon>help_outline</v-icon>
                                            </v-btn>
                                        </div>
                                        <div class="d-flex flex-row align-center pt-3">
                                            <span>{{ $t('message.exchangeRate') }}</span>
                                            <v-text-field
                                                :loading="loading.exRate"
                                                :loader-height="5"
                                                :rules="[...validationRules.required]"
                                                class="ml-3"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                min="0"
                                                solo
                                                type="number"
                                                v-model="exchangeRate"
                                            />
                                        </div>
                                    </div>
                                </v-form>
                                <div class="d-flex flex-column pl-10">
                                    <v-checkbox
                                        :label="$t('message.combineIdenticalBundles')"
                                        :true-value="true"
                                        :false-value="false"
                                        class="black--text text--darken-1"
                                        dense
                                        hide-details="auto"
                                        v-model="combineIdenticalBundles"
                                    ></v-checkbox>
                                    <v-btn
                                        class="mt-3"
                                        :disabled="tallySheetData === [] && tallySheetFile == null"
                                        :loading="uploadingFile"
                                        @click="extractTallySheet"
                                    >
                                        {{ tallySheetData.length > 0 ? $t('message.uploaded') : (separatedPages ? $t('message.readFiles') : $t('message.readFile')) }}
                                        <v-icon v-if="tallySheetData.length > 0" class="ml-2 success--text">mdi-check</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                            <div class="d-flex flex-row pb-3">
                                <v-btn color="success" @click="uploadWizard = 2" :disabled="tallySheetData.length === 0">{{ $t('message.continue') }}</v-btn>
                                <v-btn class="ml-2" @click="uploadDialog = false">{{ $t('message.cancel') }}</v-btn>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <v-simple-table
                                :fixed-header="tallySheetData.length > 20"
                                :height="tallySheetData.length > 20 ? '400px' : null"
                                class="pb-3 appic-table-light specification-table"
                                dense
                                style="width: 1500px !important;"
                            >
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="blue-grey lighten-4 pl-1">#</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[0].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[1].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[2].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[3].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[4].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[5].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[6].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[7].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[8].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[9].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[10].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[11].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[12].text }}</th>
                                            <th class="blue-grey lighten-4 pl-1">{{ headers[13].text }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(bundle, index) in tallySheetData" v-bind:key="'row_' + index.toString()">
                                            <td class="text-start font-sm">{{ index + 1 }}</td>
                                            <td :class="'text-start font-sm ' + (bundle.bundleNo.error ? 'red--text' : '')">
                                                <v-text-field
                                                    :key="'width_' + index.toString()"
                                                    :value="bundle.bundleNo.text"
                                                    clearable
                                                    dense
                                                    solo
                                                    style="width: 170px"
                                                    hide-details="auto"
                                                    @change="updateTallySheetData(index, 'bundleNo', $event)"
                                                ></v-text-field>
                                            </td>
                                            <td :class="'text-start font-sm ' + (bundle.product.error ? 'red--text' : '')">{{ bundle.product.text }}</td>
                                            <td :class="'text-start font-sm ' + (bundle.species.error ? 'red--text' : '')">{{ bundle.species.text }}</td>
                                            <td :class="'text-start font-sm ' + (bundle.certification.error ? 'red--text' : '')">{{ bundle.certification.text }}</td>
                                            <td :class="'text-start font-sm ' + (bundle.grade.error ? 'red--text' : '')">{{ bundle.grade.text }}</td>
                                            <td :class="'text-start font-sm ' + (bundle.spec.error ? 'red--text' : '')">{{ bundle.spec.text }}</td>
                                            <td :class="'text-start font-sm ' + (bundle.mc.error ? 'red--text' : '')">{{ bundle.mc.text }}</td>
                                            <td :class="'text-start font-sm ' + (bundle.thickness.error ? 'red--text' : '')">{{ bundle.thickness.text }}</td>
                                            <td :class="'text-start font-sm ' + (bundle.width.error ? 'red--text' : '')">
                                                <v-text-field
                                                    :key="'width_' + index.toString()"
                                                    :value="bundle.width.text"
                                                    clearable
                                                    dense
                                                    solo
                                                    style="width: 150px"
                                                    hide-details="auto"
                                                    @change="updateTallySheetData(index, 'width', $event)"
                                                ></v-text-field>
                                            </td>
                                            <td :class="'text-start font-sm ' + (bundle.length.error ? 'red--text' : '')">
                                                <v-text-field
                                                    :key="'length_' + index.toString()"
                                                    :value="bundle.length.text"
                                                    clearable
                                                    dense
                                                    solo
                                                    style="width: 150px"
                                                    hide-details="auto"
                                                    @change="updateTallySheetData(index, 'length', $event)"
                                                ></v-text-field>
                                            </td>
                                            <td :class="'text-start font-sm ' + (bundle.pcs.error ? 'red--text' : '')">
                                                <v-text-field
                                                    :key="'pcs_' + index.toString()"
                                                    :value="bundle.pcs.text"
                                                    clearable
                                                    dense
                                                    solo
                                                    style="width: 120px"
                                                    hide-details="auto"
                                                    @change="updateTallySheetData(index, 'pcs', $event)"
                                                ></v-text-field>
                                            </td>
                                            <td :class="'text-start font-sm ' + (bundle.quantity.error ? 'red--text' : '')">
                                                <v-text-field
                                                    :key="'quantity_' + index.toString()"
                                                    :value="bundle.quantity.text"
                                                    clearable
                                                    dense
                                                    solo
                                                    style="width: 120px"
                                                    hide-details="auto"
                                                    @change="updateTallySheetData(index, 'quantity', $event)"
                                                ></v-text-field>
                                            </td>
                                            <td :class="'text-start font-sm ' + (bundle.quantityUnit.error ? 'red--text' : '')">{{ bundle.quantityUnit.text }}</td>
                                            <td :class="'text-start font-sm ' + (bundle.buyingPrice.error ? 'red--text' : '')">
                                                <v-text-field
                                                    :key="'buying_price_' + index.toString()"
                                                    :value="bundle.buyingPrice.text"
                                                    clearable
                                                    dense
                                                    solo
                                                    style="width: 120px"
                                                    hide-details="auto"
                                                    @change="updateTallySheetData(index, 'buyingPrice', $event)"
                                                ></v-text-field>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <div class="d-flex flex-row pb-3">
                                <v-btn color="success" @click="uploadWizard = 3">{{ $t('message.continue') }}</v-btn>
                                <v-btn class="ml-2" @click="uploadDialog = false">{{ $t('message.cancel') }}</v-btn>
                            </div>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                            <div class="pb-3 font-weight-bold">{{ $t('message.add') + " " + tallySheetData.length + " " + $t('message.bundlesToList') + '.' }}</div>
                            <div class="d-flex flex-row pb-3 justify-space-between">
                                <v-btn @click="addToBundleList" :loading="addingToBundleList" color="success">
                                    {{ this.addedToBundleList ? $t('message.added') : $t('message.add') }}
                                    <v-icon v-if="this.addedToBundleList" class="ml-2 white--text">mdi-check</v-icon>
                                </v-btn>
                                <v-checkbox
                                    :label="$t('message.clearCurrentBundles')"
                                    :true-value="true"
                                    :false-value="false"
                                    class="ml-1 black--text text--darken-1"
                                    dense
                                    hide-details="auto"
                                    v-model="resetCurrentList"
                                ></v-checkbox>
                                <v-spacer></v-spacer>
                                <v-btn @click="uploadDialog = false">{{ this.addedToBundleList ? $t('message.close') : $t('message.cancel') }}</v-btn>
                            </div>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {api} from "Api";

export default {
    name: "TallySheetUpload",
    props: [
        'dialog',
        'officeId',
        'purchaseOrderId',
        'stockOrderId',
        'updateMode',
    ],
    data() {
        return {
            addedToBundleList: false,
            addingToBundleList: false,
            combineIdenticalBundles: false,
            dialogs: {
                error: false,
                error_message: ""
            },
            exchangeRate: 1,
            hasFile: {
                tallySheet: true,
                sizes: false,
                quantities: false
            },
            headers: [
                { id: 1, text: this.$t('message.bundleNo') },
                { id: 2, text: this.$t('message.product') },
                { id: 3, text: this.$t('message.species') },
                { id: 4, text: this.$t('message.certification') },
                { id: 5, text: this.$t('message.grade') },
                { id: 6, text: this.$t('message.spec') },
                { id: 7, text: this.$t('message.mc') },
                { id: 8, text: this.$t('message.thickness') },
                { id: 9, text: this.$t('message.width') },
                { id: 10, text: this.$t('message.length') },
                { id: 11, text: this.$t('message.pcs') },
                { id: 12, text: this.$t('message.quantity') },
                { id: 13, text: this.$t('message.unit') },
                { id: 14, text: this.$t('message.buyingPrice') }
            ],
            loading: {
                add: false,
                exRate: false,
                get: false,
                save: false
            },
            manualErrorState: [null, null, null, null, null, null],
            prompts: [],
            resetCurrentList: false,
            systemInstructions: [],
            separatedPages: false,
            tallySheetData: [],
            tallySheetFile: null,
            tallySheetFileTwo: null,
            tallySheetFileThree: null,
            tallySheetFileFour: null,
            tallySheetSizesFile: null,
            tallySheetQuantitiesFile: null,
            upload_dialog: false,
            uploadingFile: false,
            uploadWizard: 1,
            validForm: false
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('stock',{
            Bundles: 'Bundles'
        }, 'statePrefix'),
        ...mapFieldsPrefixed('stockorder',{
            StockOrder__currencyrate: 'StockOrder.currencyrate'
        }, 'statePrefix'),
        ...mapGetters('certification',{
            allCertifications: 'allCertifications'
        }),
        ...mapGetters('grade',{
            allGrades: 'allGrades',
            findGradeById: 'findGradeById'
        }),
        ...mapGetters('mc', {
            allMoistureContent: 'allMoistureContent'
        }),
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        ...mapGetters('species', {
            allSpecies: 'allSpecies',
            findSpeciesById: 'findSpeciesById'
        }),
        ...mapGetters('spec',{
            allSpecs: 'allSpecs',
            allCleanedSpecs: 'allCleanedSpecs',
            findSpecById: 'findSpecById'
        }),
        ...mapGetters('stock',{
            defaultBundle: 'defaultBundle'
        }),
        ...mapGetters('thickness', {
            allThickness: 'allThickness'
        }),
        ...mapGetters([
            'offices',
            'uofmDimensions',
            'uofmQty',
            'validationRules'
        ]),
        uploadDialog: {
            get() {
                return this.upload_dialog;
            },
            set(value){
                this.upload_dialog = value
                if(value === false) {
                    this.uploadWizard = 1;
                    this.apiKey = null;
                    this.combineIdenticalBundles = false;
                    this.exchangeRate = 1;
                    this.prompts = [];
                    this.manualErrorState = [null, null, null, null, null, null];
                    this.separatedPages = false;
                    this.systemInstructions = [];
                    this.tallySheetFile = null;
                    this.tallySheetFileTwo = null;
                    this.tallySheetFileThree = null;
                    this.tallySheetFileFour = null;
                    this.tallySheetSizesFile = null;
                    this.tallySheetQuantitiesFile = null;
                    this.tallySheetData = [];
                    this.hasFile.tallySheet = false;
                    this.hasFile.sizes = false;
                    this.hasFile.quantities = false;
                    this.$emit('dialog-closed');
                }
            }
        },
    },
    methods: {
        ...mapActions('certification',{
            getAllCertifications: 'getAllCertifications'
        }),
        ...mapActions('grade', {
            getAllGrades: 'getAllGrades'
        }),
        ...mapActions('mc', {
            getAllMoistureContent: 'getAllMoistureContent'
        }),
        ...mapActions('productgroup', {
            getAllProductGroups: 'getAllProductGroups'
        }),
        ...mapActions('size', {
            getAllSizes: 'getAllSizes'
        }),
        ...mapActions('species', {
            getAllSpecies: 'getAllSpecies'
        }),
        ...mapActions('spec', {
            getAllSpecs: 'getAllSpecs',
            getAllCleanedSpecs: 'getAllCleanedSpecs'
        }),
        ...mapActions('stock', {
            clearStockOrderBundles: 'clearStockOrderBundles',
        }),
        ...mapActions('thickness', {
            getAllThickness: 'getAllThickness'
        }),
        addToBundleList() {
            this.addingToBundleList = true

            let i = 1
            if(this.resetCurrentList) {
                this.clearStockOrderBundles(this.statePrefix);
            } else {
                if (this.Bundles.length > 0) {
                    i = this.Bundles.length + 1
                }
            }

            let newBundles = [];
            this.tallySheetData.forEach(row => {
                let bundle = _.cloneDeep(this.defaultBundle)
                bundle.index = i
                bundle.lineNumber = i
                bundle.Stock.bundleno = row.bundleNo.value
                bundle.Stock.certification_id = row.certification.value
                bundle.Stock.costprice = row.buyingPrice.value
                // bundle.Stock.forwardingcharge = row.freightCost.value
                bundle.Stock.grade_id = row.grade.value
                // bundle.Stock.importduty = row.importDuty.value
                bundle.Stock.length = row.length.value
                bundle.Stock.mc_id = row.mc.value
                bundle.Stock.piece = row.pcs.value
                bundle.Stock.productgroup_id = row.product.value
                bundle.Stock.spec_id = row.spec.value
                bundle.Stock.species_id = row.species.value
                bundle.Stock.thickness_id = row.thickness.value
                bundle.Stock.volume = row.quantity.value
                // bundle.Stock.warehousecost = row.storageCost.value
                bundle.Stock.width = row.width.value

                bundle.Product.id = row.product.value
                bundle.Product.title = row.product.text
                bundle.Species.id = row.species.value
                bundle.Species.title = row.species.text
                bundle.Grade.id = row.grade.value
                bundle.Grade.title = row.grade.text
                bundle.Spec.id = row.spec.value
                bundle.Spec.title = row.spec.text
                bundle.Mc.id = row.mc.value
                bundle.Mc.title = row.mc.text
                bundle.Soldmeasurement.id = row.quantityUnit.value
                bundle.Soldmeasurement.title = row.quantityUnit.text

                newBundles.unshift(bundle)
                i++
            })

            if(newBundles.length > 0) {
                newBundles.reverse();

                const originalBundles = _.cloneDeep(this.Bundles);

                this.Bundles = [...originalBundles, ...newBundles]
            }

            if(i === this.Bundles.length + 1){
                this.addingToBundleList = false
                this.addedToBundleList = true
                this.$toast.success(this.$t('message.successes.bundlesAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'check_circle_outline'
                    }
                )
            } else {
                this.addingToBundleList = false
                this.$toast.error(this.$t('message.errors.bundlesNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }

            this.StockOrder__currencyrate = this.exchangeRate;

        },
        checkFile(idx, fileType, fileObj) {
            if(fileObj) {
                let allowedExtensions = null;
                let errorMessage = '';

                if(fileType === 'pdf') {
                    allowedExtensions = this.validationRules.validPdfFileExtensions
                    errorMessage = 'PDF files only'
                }
                if(fileType === 'image') {
                    allowedExtensions = this.validationRules.validImageFileExtensions
                    errorMessage = 'JPg, JPEG, PNG, GIF files only'
                }

                if(allowedExtensions) {
                    if (fileObj.type === "" || allowedExtensions.exec(fileObj.name) == null) {
                        this.manualErrorState[idx] = errorMessage
                    } else {
                        this.manualErrorState[idx] = '';
                    }
                } else {
                    this.manualErrorState[idx] = 'Cannot check the file.';
                }
            }
        },
        continueWizard(nextStep) {
            switch(nextStep){
                case 1:
                    break
                case 2:
                    break
                case 3:
                    break
            }
        },
        extractTallySheet() {
            if(this.$refs.fileUploadForm.validate()) {
                this.uploadingFile = true;

                const formData = new FormData();
                formData.append('tallySheet', this.tallySheetFile);
                formData.append('tallySheetTwo', this.tallySheetFileTwo);
                formData.append('tallySheetThree', this.tallySheetFileThree);
                formData.append('tallySheetFour', this.tallySheetFileFour);
                formData.append('tallySheetSizes', this.tallySheetSizesFile);
                formData.append('tallySheetQuantities', this.tallySheetQuantitiesFile);
                formData.append('purchaseOrderId', this.purchaseOrderId);
                formData.append('stockOrderId', this.stockOrderId);
                formData.append('combineIdenticalBundles', this.combineIdenticalBundles);
                formData.append('exchangeRate', this.exchangeRate);

                api
                    .post('/ai/parse-tally-sheet', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((response) => {
                        if (response.data.status === 'success') {
                            this.fillTable(response.data.data);
                            // this.uploadingFile = false; moved to fillTable()
                        } else {
                            this.$toast.error(this.$t('message.errors.documentNotParsed'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.uploadingFile = false;
                        }
                    })
                    .catch((err) => {
                        this.$toast.error(this.$t('message.errors.documentNotParsed'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.uploadingFile = false;
                    })
            } else {
                this.$toast.error(this.$t('message.errors.documentNotParsed'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        fillTable(tallySheetData) {
            const _this = this;
            tallySheetData.forEach((row) => {
                let bundle = {
                    bundleNo: {
                        value: row['bundleNo'],
                        text: row['bundleNo'],
                        error: row['bundleNo'] == null
                    },
                    product: {
                        value: row['product_id'],
                        text: row['product'] ? row['product'] : _this.$t('message.empty'),
                        error: row['product_id'] == null
                    },
                    species: {
                        value: row['species_id'],
                        text: row['species'] ? row['species'] : _this.$t('message.empty'),
                        error: row['species_id'] == null
                    },
                    certification: {
                        value: row['certification_id'],
                        text: row['certification'] ? row['certification'] : _this.$t('message.empty'),
                        error: row['certification_id'] == null
                    },
                    grade: {
                        value: row['grade_id'],
                        text: row['grade'] ? row['grade'] : _this.$t('message.empty'),
                        error: row['grade_id'] == null
                    },
                    spec: {
                        value: row['spec_id'],
                        text: row['spec'] ? row['spec'] : _this.$t('message.empty'),
                        error: row['spec_id'] == null
                    },
                    mc: {
                        value: row['mc_id'],
                        text: row['mc'] ? row['mc'] : _this.$t('message.empty'),
                        error: row['mc_id'] == null
                    },
                    thickness: {
                        value: row['thickness_id'],
                        text: row['thickness'] ? row['thickness'] : _this.$t('message.empty'),
                        error: row['thickness_id'] == null
                    },
                    width: {
                        value: row['width'],
                        text: row['width'] ? row['width'] : _this.$t('message.empty'),
                        error: row['width'] == null
                    },
                    length: {
                        value: row['length'],
                        text: row['length'] ? row['length'] : _this.$t('message.empty'),
                        error: row['length'] == null
                    },
                    pcs: {
                        value: row['pcs'],
                        text: row['pcs'] ? row['pcs'] : _this.$t('message.empty'),
                        error: row['pcs'] == null
                    },
                    quantity: {
                        value: row['quantity'],
                        text: row['quantity'] ? row['quantity'] : _this.$t('message.empty'),
                        error: row['quantity'] == null
                    },
                    quantityUnit: {
                        value: row['unit_id'],
                        text: row['unit'] ? row['unit'] : _this.$t('message.empty'),
                        error: row['unit_id'] == null
                    },
                    buyingPrice: {
                        value: row['buyingPrice'],
                        text: row['buyingPrice'] ? row['buyingPrice'] : _this.$t('message.empty'),
                        error: row['buyingPrice'] == null
                    }

                }
                this.tallySheetData.push(bundle)
            })
            this.uploadingFile = !this.uploadingFile;

            if(this.statePrefix === 'current') {
                this.resetCurrentList = true
            }
        },
        getUpdatedExRate() {
            let warehouseCurrency = 'MYR';

            if(this.officeId === 21) warehouseCurrency = 'IDR';

            const currencies = {
                source: 'USD',
                target: warehouseCurrency
            };

            this.loading.exRate = true;

            api
                .put('/reports/stocks/user-exchange-rate', {
                    currencies: currencies
                })
                .then((response) => {
                    if(response.data.status === 'success') {
                        this.exchangeRate = response.data.rate
                    } else {
                        this.$toast.error(this.$t('message.errors.exchangeRateNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    this.loading.exRate = false;
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.exchangeRateNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.exRate = false;
                })
        },
        updateTallySheetData(index, field, newValue) {
            //only for width, length, pcs and qty
            this.tallySheetData[index][field]['text'] = newValue
            this.tallySheetData[index][field]['value'] = newValue
        }
    },
    watch: {
        dialog(value) {
            this.upload_dialog = value
            if(!value) {
                this.addedToBundleList = false;
                this.apiKey = null;
                this.combineIdenticalBundles = false;
                this.exchangeRate = 1;
                this.systemInstructions = [];
                this.prompts = [];
                this.tallySheetFile = null;
                this.tallySheetFileTwo = null;
                this.tallySheetFileThree = null;
                this.tallySheetFileFour = null;
                this.tallySheetSizesFile = null;
                this.tallySheetQuantitiesFile = null;
                this.tallySheetData = [];
                this.hasFile.tallySheet = true;
                this.hasFile.sizes = false;
                this.hasFile.quantities = false;
                this.manualErrorState = [null, null, null, null, null, null];
                this.prompts = [];
                this.resetCurrentList = false;
                this.separatedPages = false;
                this.uploadingFile = false;
                this.uploadWizard = 1;
                this.validForm = false;
            } else {
                this.getUpdatedExRate()
            }
        }
    },
    created() {
        if(this.allSpecies.length === 0) this.getAllSpecies()
        if(this.allGrades.length === 0) this.getAllGrades()
        if(this.allCertifications.length === 0) this.getAllCertifications()
        if(this.allMoistureContent.length === 0) this.getAllMoistureContent()
        if(this.allProductGroups.length === 0) this.getAllProductGroups()
        if(this.allCleanedSpecs.length === 0) this.getAllCleanedSpecs()
        if(this.allThickness.length === 0) this.getAllThickness()
    }
}
</script>

<style scoped>
.v-dialog__content::v-deep .v-dialog {
    min-width: 1500px !important;
}
</style>